import React from "react"
import classNames from "classnames"
import BrandGraphics from "Accessories/BrandGraphics/BrandGraphics"
import "./IntroText.scss"

const IntroText = ({intro, headline, genericImages, classes}) => {
  const getRandomColor = () => {
    const colorValues = ["#3573b7", "#6f9ea4", "#ae5647", "#db9482"];
    return colorValues[Math.floor(Math.random() * colorValues.length)];
  }

  function getRandomIntInclusive(min, max) {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
  }

  return (
    <section className={classNames("container-fluid section intro-text-section", classes)}>
      <div className="container">
        {headline && (
          <div className="row">
            <h1 className="col-12 col-md-8 col-lg-6 offset-md-4 offset-lg-6 mb-1" style={{color: getRandomColor()}}>{headline}</h1>
          </div>
        )}
        {intro && (intro.paragraphs.map((paragraph, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="row" key={paragraph + index}>
            <div
              className={
                classNames("col-12 col-md-8 col-lg-6 text-lg",
                {"offset-md-4 offset-lg-6 mb-5": index % 2 === 0})
              }
              dangerouslySetInnerHTML={{ __html: paragraph.paragraph }}
            />
          </div>
        )))}
        <div className="row pattern-container pt-3 d-none d-md-block">
          <div className={classNames(`col-md-${getRandomIntInclusive(2, 3)} col-lg-${getRandomIntInclusive(1, 2)} col-xl-1 offset-md-${getRandomIntInclusive(0, 3)}`)}>
            <BrandGraphics graphicType="pill" genericImages={genericImages} spacingTop={(getRandomIntInclusive(0, 12))} />
          </div>
          <div className={classNames(`col-md-${getRandomIntInclusive(2, 3)} col-lg-${getRandomIntInclusive(1, 2)} col-xl-1 offset-md-${getRandomIntInclusive(7, 9)}`)}>
            <BrandGraphics graphicType="pill" genericImages={genericImages} spacingTop={(getRandomIntInclusive(8, 10))} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntroText
