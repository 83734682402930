import React, {useState, useEffect} from 'react'
import {Link} from "gatsby"
import "./EventTeaser.scss"

const EventTeaser = (props) => {
  const { events } = props
  const [bottom, setBottom] = useState(false)

  function handleScroll() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight
    const { body } = document
    const html = document.documentElement
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight)
    const windowBottom = windowHeight + window.pageYOffset

    if (windowBottom >= docHeight) {
      setBottom(true)
    } else {
      setBottom(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  if (events.edges.length > 3) events.edges.length = 3

  return (
    <div className={`event-teaser-section section container-fluid d-none d-lg-flex text-sm ${bottom ? 'is-hidden' : 'is-visible'}`}>
      {events.edges.length > 0 &&
        (events.edges
          .map(event => {
          const eventCategories = event.node.categories_names.filter(item => item.taxonomy === "types")
          const eventLocation = event.node.acf.event.event_venue && event.node.acf.event.event_venue
          const eventYear = event.node.categories_names.filter(item => item.taxonomy === "years")

          return (
            <Link
              to={`/events/${event.node.slug}`}
              key={event.node.slug}
              className="event-wrapper col-md-4"
              data-location={eventLocation}
              data-year={eventYear.length && eventYear[0].name}
              data-type={eventCategories.length && eventCategories[0].name}
            >
              <div className="event-title">
                {
                  eventCategories.length && <span className="bracket-text d-block">{eventCategories[0].name}</span>
                }
                <span>{event.node.title}</span>
                {
                  event.node.acf.event.event_subtitle && <span>{` • ${event.node.acf.event.event_subtitle}`}</span>
                }
              </div>
              <div className="event-info">
                {event.node.acf.event.speaker_name && <h3 className="d-block">{event.node.acf.event.speaker_name}</h3>}
                {
                  event.node.acf.event.event_date_info && (
                    <span>
                      {event.node.acf.event.event_date_info.event_date_info_start_date && event.node.acf.event.event_date_info.event_date_info_start_date}
                      {event.node.acf.event.event_date_info.event_date_info_start_time && ` ${event.node.acf.event.event_date_info.event_date_info_start_time}`}
                      {event.node.acf.event.event_date_info.event_date_info_end_date && ` – ${event.node.acf.event.event_date_info.event_date_info_end_date}`}
                      {event.node.acf.event.event_date_info.event_date_info_end_time && ` ${event.node.acf.event.event_date_info.event_date_info_end_time}`}
                    </span>
                  )
                }
                {eventLocation && <span className="d-block">{eventLocation}</span>}
              </div>
            </Link>
          )
        }))
      }
    </div>
  )
}

export default EventTeaser
