import React from 'react';
import './TypoOverlay.scss';

import { ReactComponent as SvgParenthesis1 } from "static/assets/typooverlay/parenthesis-1.svg";
import { ReactComponent as SvgParenthesis2 } from "static/assets/typooverlay/parenthesis-2.svg";
import { ReactComponent as SvgC } from "static/assets/typooverlay/c.svg";
import { ReactComponent as SvgP } from "static/assets/typooverlay/p.svg";
import { ReactComponent as SvgT } from "static/assets/typooverlay/t.svg";

const TypoOverlay = () => {

  return (
    <div className="typo-overlay">
      <div className="container-fluid">
        <div className="row">
          <div className="line line-1">
            <SvgParenthesis1 />
            <SvgC className="glyph-c-3" />
            <SvgC className="glyph-c-4" />
            <SvgC className="glyph-c-2" />
            <SvgC className="glyph-c-6" />
            <SvgC className="glyph-c-7" />
            <SvgC className="glyph-c-8" />
          </div>

          <div className="line line-2">
            <SvgC className="glyph-c-1" />
            <SvgC className="glyph-c-5" />
            <SvgP className="glyph-p-4" />
            <SvgP className="glyph-p-2" />
            <SvgP className="glyph-p-6" />
            <SvgP className="glyph-p-7" />
            <SvgP className="glyph-p-8" />
            <SvgT className="glyph-t-2" />
          </div>

          <div className="line line-3">
            <SvgP className="glyph-p-1" />
            <SvgP className="glyph-p-5" />
            <SvgP className="glyph-p-3" />
            <SvgT className="glyph-t-1" />
            <SvgT className="glyph-t-5" />
            <SvgT className="glyph-t-3" />
            <SvgT className="glyph-t-4" />
            <SvgT className="glyph-t-6" />
            <SvgT className="glyph-t-7" />
            <SvgT className="glyph-t-8" />
            <SvgParenthesis2 />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypoOverlay