import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import classNames from "classnames";
import Img from "gatsby-image";
import { InView } from "react-intersection-observer";
import "./BrandGraphics.scss";

const BrandGraphics = (props) => {

  const { graphicType, animateOnInView, spacingTop, width, animationStyle, genericImages } = props;
  const [isInView, setIsInView] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  const getRandomIntInclusive = (min, max) => {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
  }

  const imageIndex = getRandomIntInclusive(0,(genericImages.length - 1));

  useEffect(() => {
    const { animateOnTime } = props;

    if(animateOnTime) {
      setStartAnimation(true);
    }
  });

  const createElement = () => {
    switch(graphicType) {
      case "pill":
        return (
          <div className="pill-wrapper">
            {
              genericImages[imageIndex].image && genericImages[imageIndex].image.localFile && genericImages[imageIndex].image.localFile.childImageSharp.fluid && (
                <Img
                  fluid={genericImages[imageIndex].image.localFile.childImageSharp.fluid}
                />
              )
            }
          </div>
        )

      default:
        return "null"
    }
  }

  const classes = classNames( "brand-graphic", 
                              `spacing-mt-${spacingTop}`,
                              `${width}`, 
                              `animation-${animationStyle}`, 
                              {"animated" : isInView || startAnimation }
                            )

  return (
    <div className={classes}>
      {animateOnInView ? (
        <InView onChange={inView => setIsInView(inView)}>
          {createElement()}
        </InView>
        ) : ( 
          <>
            {createElement()}
          </>
      )}
    </div>
  )
}

export default BrandGraphics

BrandGraphics.propTypes = {
  graphicType: PropTypes.string,
  spacingTop: PropTypes.number,
  width: PropTypes.string,
  animateOnInView: PropTypes.bool,
  animationStyle: PropTypes.string
};

BrandGraphics.defaultProps = {
  graphicType: "pill",
  spacingTop: null,
  width: "",
  animateOnInView: false,
  animationStyle: "none"
};