import React from "react"
import classNames from "classnames"
import {Link} from "gatsby"
import Img from "gatsby-image"
import "./VisualNavigation.scss"

const VisualNavigationLink = ({
  slug,
  target,
  url,
  title,
  genericImages,
  index
}) => {

  const gradientDirections = [
    "to top",
    "to right",
    "to bottom",
    "to left"
  ]

  const gradientTypes = [
    "rgba(45,45,45,0), rgba(66,66,66,1)",
    "rgba(125,125,125,0), rgba(125,125,125,1)",
    "rgba(38,38,38,0), rgba(38,38,38,1)",
    "rgba(192,192,192,0), rgba(192,192,192,1)"
  ]

  const getRandomIntInclusive = (min, max) => {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min)
  }

  const imageIndex = getRandomIntInclusive(0,(genericImages.length - 1))
  const gradientDirection = getRandomIntInclusive(0,3)
  const gradientType = getRandomIntInclusive(0,3)
  const linkCls = classNames(
    "item-wrapper",
    "col-sm-4",
    {
      "offset-sm-2": index !== 2 && index !== 3 && index !== 4,
      "offset-sm-1": index === 3,
      "offset-sm-3": index === 4
    }
  )

  const renderChildren = () => {
    return (
      <>
        <div
          className="item-bg"
          style={{backgroundImage: `linear-gradient(${gradientDirections[gradientDirection]}, ${gradientTypes[gradientType]}`}}
        />
        <div className="item-content p-1">
          {
            genericImages[imageIndex].image && genericImages[imageIndex].image.localFile && genericImages[imageIndex].image.localFile.childImageSharp.fluid && (
              <div className="item-image">
                <Img
                  fluid={genericImages[imageIndex].image.localFile.childImageSharp.fluid}
                  className="round-image"
                />
              </div>
            )
          }
          <div className="item-title text-dot">
            <span className="bracket-text">{title}</span>
          </div>
        </div>
      </>
    )
  }

  if (target !== "_blank") {
    return (
      <Link
        to={`/${slug}/`}
        key={slug}
        className={linkCls}
      >
        {renderChildren()}
      </Link>
    )
  }

  return (
    <a
      key={slug}
      className={linkCls}
      href={url}
      target={target}
      rel="noopener noreferrer"
    >
      {renderChildren()}
    </a>
  )
}

export default VisualNavigationLink
